import cookiesLanguageSettings from '../utils/cookies-language-settings';
/**
 * @class Cookieconsent
 */
class CookieConsent {
  /**
   * Creates an instance of CookieConsent.
   *
   * @memberof CookieConsent
   */

  constructor() {
    /**
     * Init cookie consent modal
     */
    // eslint-disable-next-line no-undef
    this.cookiesConsentModal = initCookieConsent();
    this.currentLanguage = document.getElementById('currentLanguage')?.value;
  }

  /**
   * init the class
   */
  ready() {
    document.documentElement.classList.add('freeze');
    this.cookiesConsentModal.run({
      current_lang: this.currentLanguage,
      autoclear_cookies: true,
      cookie_name: 'cookie_consent',
      cookie_expiration: 365,
      page_scripts: true,
      autorun: true,
      force_consent: true,
      onAccept: function () {
        document.documentElement.classList.remove('freeze');
      },
      gui_options: {
        consent_modal: {
          layout: 'box', // box,cloud,bar
          position: 'bottom right', // bottom,middle,top + left,right,center
          transition: 'slide', // zoom,slide
        },
        settings_modal: {
          layout: 'box', // box,bar
          // position: 'left',                // right,left (available only if bar layout selected)
          transition: 'slide', // zoom,slide
        },
      },
      languages: {
        en: cookiesLanguageSettings.en,
      },
    });
  }
}

export default new CookieConsent();
