class TogglePassword {
  ready() {
    // eslint-disable-next-line no-undef
    const passwordContainers = document.querySelectorAll(
      '.form__input-container--password'
    );
    passwordContainers.forEach((container) => {
      const passwordInput = container.querySelector(
        'input[type="password"], input[type="text"]'
      );
      const showPasswordButton = container.querySelector('.password-btn--show');
      const hidePasswordButton = container.querySelector('.password-btn--hide');
      showPasswordButton.addEventListener('click', () => {
        passwordInput.type = 'text';
        showPasswordButton.style.display = 'none';
        hidePasswordButton.style.display = 'block';
      });
      hidePasswordButton.addEventListener('click', () => {
        passwordInput.type = 'password';
        hidePasswordButton.style.display = 'none';
        showPasswordButton.style.display = 'block';
      });
    });
  }
}

export default new TogglePassword();
