class HandleSelect {
  ready() {
    // eslint-disable-next-line no-undef
    const specialitySelect = document.querySelector(
      '.form__select-container select[name="speciality"]'
    );
    // eslint-disable-next-line no-undef
    const otherSpecialityInput = document.querySelector(
      'input[name="other_speciality"]'
    );

    if (specialitySelect && otherSpecialityInput) {
      toggleOtherSpecialityInput(specialitySelect.value);

      specialitySelect.addEventListener('change', function () {
        toggleOtherSpecialityInput(this.value);
      });
    }

    function toggleOtherSpecialityInput(selectedValue) {
      if (selectedValue === 'other') {
        otherSpecialityInput.disabled = false;
      } else {
        otherSpecialityInput.value = '';
        otherSpecialityInput.disabled = true;
      }
    }

    // eslint-disable-next-line no-undef
    document
      .querySelectorAll('.form__select-container select')
      .forEach(function (selectElement) {
        selectElement.addEventListener('change', function () {
          if (this.value === '') {
            this.classList.remove('valid-selection');
          } else {
            this.classList.add('valid-selection');
          }
        });

        selectElement.dispatchEvent(new Event('change'));
      });
  }
}

export default new HandleSelect();
