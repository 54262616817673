const website = window.location.hostname;
const privacyPolicyElement = document.getElementById('privacy-policy');
const privacyPolicy = privacyPolicyElement ? privacyPolicyElement.value : '';

const cookiesLanguageSettings = {
  en: {
    consent_modal: {
      title: ' We use cookies! ',
      description:
        'This site uses essential cookies to ensure its proper operation, especially for mobile view preferences, and tracking cookies from GA4, Hotjar, and Bing Analytics to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
      primary_btn: {
        text: 'Accept all',
        role: 'accept_all',
      },
      secondary_btn: {
        text: 'Reject all',
        role: 'accept_necessary',
      },
    },
    settings_modal: {
      title: 'Cookies settings',
      save_settings_btn: 'Save settings',
      accept_all_btn: 'Accept all',
      reject_all_btn: 'Reject all',
      close_btn_label: 'Close',
      cookie_table_headers: [
        { col1: 'Name' },
        { col2: 'Domain' },
        { col3: 'Expiration' },
        { col4: 'Description' },
      ],
      blocks: [
        {
          title: 'Cookie usage',
          description:
            'This site uses cookies to ensure basic functionalities of the website, particularly for mobile view preferences, and to enhance your online experience. You can choose to accept or decline each category anytime. For more details about cookies and other sensitive data.',
        },
        {
          title: 'Strictly necessary cookies',
          description:
            "These cookies are essential for the proper functioning of the website, especially for mobile view preferences. Without these cookies, the website wouldn't function properly",
          toggle: {
            value: 'necessary',
            enabled: true,
            readonly: true,
          },
        },
        {
          title: 'Performance and Analytics cookies',
          description:
            'These cookies, sourced from GA4, Hotjar, and Bing Analytics, allow us to understand user interactions, improve the user experience, and analyze traffic.',
          toggle: {
            value: 'analytics',
            enabled: false,
            readonly: false,
          },
          cookie_table: [
            {
              col1: '_ga',
              col2: 'google.com',
              col3: '2 years',
              col4: 'Used by Google Analytics (GA4) to distinguish users.',
              is_regex: true,
            },
            {
              col1: '_hjid',
              col2: website,
              col3: '1 year',
              col4: 'Used by Hotjar to track user interactions.',
            },
            {
              col1: '_uetsid',
              col2: 'bing.com',
              col3: '30 minutes',
              col4: 'Used by Bing Analytics to track user sessions.',
            },
          ],
        },
        {
          title: 'More information',
          description: privacyPolicy
            ? `For any inquiries regarding our cookie policy and your choices, please refer to our <br> <a class="cc-link" href="${privacyPolicy}">Privacy Policy</a>.`
            : 'For any inquiries regarding our cookie policy and your choices, please refer to our Privacy Policy.',
        },
      ],
    },
  },
};

export default cookiesLanguageSettings;
