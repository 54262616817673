import chroma from 'chroma-js';

export const getPalette = (color) => {
  const colors = chroma.scale(['white', color, 'black']);
  const palette = [];

  palette.push(colors(0.05).hex());

  for (let i = 0.1; i < 0.9; i += 0.1) {
    palette.push(colors(i).hex());
  }
  return palette;
};

export const isValid = (color) => chroma.valid(color);
