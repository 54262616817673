import '../styles/main.scss';
import '../styles/tailwind.css';
import CookieConsent from '../utils/cookieconsent';
import HandleSelect from '../utils/handle-select';
import { generateCSSVariables } from '../utils/helpers';
import TogglePassword from '../utils/toggle-password';
import './onboarding';

/**
 * Run Script on site load
 */
document.addEventListener('DOMContentLoaded', () => {
  (() => {
    generateCSSVariables();
    TogglePassword.ready();
    HandleSelect.ready();
    CookieConsent.ready();
  })();
});
