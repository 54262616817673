import axios from 'axios';
import html2pdf from 'html2pdf.js';
import Cookies from 'js-cookie';

import { getPalette } from '../services/palleteGenerator';

/**
 * Get cookie by name
 * we can use it to get django csrf token too
 * @param csrftoken
 */
export const getCookie = (csrftoken) => {
  return Cookies.get(csrftoken);
};

/*
 * Get current Django selected languagd
 * @param {*} defaultLanguage
 * @returns
 */
export const getCurrentLanguageCode = (defaultLanguage = 'fr') => {
  // eslint-disable-next-line no-undef
  const languageCode = document.querySelector('#language-code');

  return languageCode?.length > 0 ? languageCode.value : defaultLanguage;
};

/**
 * Get current site activity title
 * @returns {T|string}
 */
export const getSiteActivityTitle = () => {
  // eslint-disable-next-line no-undef
  const siteActivityTitle = document.querySelector('#activity-title');
  return siteActivityTitle?.value || '';
};

/**
 * Get current site activity description
 * @returns {T|string}
 */
export const getSiteActivityDescription = () => {
  // eslint-disable-next-line no-undef
  const siteActivityDescription = document.querySelector(
    '#activity-description'
  );
  return siteActivityDescription?.value || '';
};

/**
 * Get current site colors
 * @returns {{primaryColor: string, secondaryColor: string}}
 */
const getCurrentSiteColors = () => {
  // eslint-disable-next-line no-undef
  const primaryColor = document.querySelector('#primary-color');
  // eslint-disable-next-line no-undef
  const secondaryColor = document.querySelector('#secondary-color');

  return {
    primaryColor: primaryColor.value,
    secondaryColor: secondaryColor.value,
  };
};

/**
 * Generate CSS variables for primary and secondary color
 */
export const generateCSSVariables = () => {
  const { primaryColor, secondaryColor } = getCurrentSiteColors();
  const primaryPalette = getPalette(primaryColor);
  const secondaryPalette = getPalette(secondaryColor);

  //eslint-disable-next-line no-undef
  const root = document.documentElement;

  const steps = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

  primaryPalette.forEach((color, index) => {
    root.style.setProperty(`--color-primary-${steps[index]}`, color);
  });
  root.style.setProperty('--color-primary-default', primaryColor);
  secondaryPalette.forEach((color, index) => {
    root.style.setProperty(`--color-secondary-${steps[index]}`, color);
  });
  root.style.setProperty('--color-secondary-default', secondaryColor);
};

/**
 * Get current tenant to use as BASE_API_URL
 * @returns {string}
 */
export const getCurrentTenant = () => {
  // eslint-disable-next-line no-undef
  const hostname = document.querySelector('#hostname');
  return hostname?.value || process.env.BASE_URL;
};

/**
 * Download case summary as PDF
 * @param caseId
 * @returns {Promise<void>}
 */
export const downloadCaseSummary = async (caseId) => {
  try {
    const response = await axios.get(`/case-summary/${caseId}/`);

    const html = response.data;

    // eslint-disable-next-line no-undef
    const tempElement = document.createElement('div');
    tempElement.style.display = 'none';
    tempElement.innerHTML = html;

    // Extract the inner HTML for PDF generation
    const source = tempElement.innerHTML;

    html2pdf()
      .from(source)
      .set({
        filename: 'case-summary.pdf',
        margin: [15, 15],
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          letterRendering: true,
        },
        jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        autoPaging: 'text',
      })
      .save();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error downloading the case summary:', error);
  }
};
